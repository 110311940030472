.property-cards {
    position: relative;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    cursor: pointer;
    min-height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 380px;
    font-size: 24px;
 
}
.property-images{
    position: relative;
    

}
.property-images  {
    width: 100%;
     height:53%;
    object-fit: cover; 
    border-bottom: 1px solid #e0e0e0;
}

.property-infos {
   padding-left: 10px;
   
    padding-top: 0;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content:space-around;

 
}



.LinkProperties {
    text-decoration: none; 
    color: inherit; 
    display: block; 
    height: 100%;
}

.property-metas {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.prices {
    font-weight: bold;
    color: #2c3e50;
}

.locations {
    color: #6b7280;
    font-size: 0.9rem;
}


@media (max-width:600px) {
    .property-cards{
        font-size: 1rem;
    }
}